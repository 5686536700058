import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
} from '@wix/tpa-settings';

export type IStylesParams = {
  productTitleColor: StyleParamType.Color;
  productTitleFont: StyleParamType.Font;
  productPriceColor: StyleParamType.Color;
  productPriceFont: StyleParamType.Font;
  productDescriptionColor: StyleParamType.Color;
  productDescriptionFont: StyleParamType.Font;
  backgroundColor: StyleParamType.Color;
  imageBorderColor: StyleParamType.Color;
  inputFieldBackgroundColor: StyleParamType.Color;
  inputFieldBorderColor: StyleParamType.Color;
  inputFieldBorderWidth: StyleParamType.Number;
  inputFieldFont: StyleParamType.Font;
  inputFieldColor: StyleParamType.Color;
  inputFieldTitlesColor: StyleParamType.Color;
  inputFieldTitlesFont: StyleParamType.Font;
  ProductVariantsFillColorOpacity: StyleParamType.Color;
  ProductVariantsBorderColor: StyleParamType.Color;
  ProductVariantsBorderWidth: StyleParamType.Number;
  ProductVariantsSelectedBoxColor: StyleParamType.Color;
  ProductVariantsTextColor: StyleParamType.Color;
  ProductVariantsTextFont: StyleParamType.Font;
  BuyNowButtonTextFont: StyleParamType.Font;
  BuyNowButtonTextColor: StyleParamType.Color;
  BuyNowButtonCornerRadius: StyleParamType.Number;
  BuyNowButtonBorderColor: StyleParamType.Color;
  BuyNowButtonBorderWidth: StyleParamType.Number;
  BuyNowButtonFillColorOpacity: StyleParamType.Color;

  AddToCartButtonTextFont: StyleParamType.Font;
  AddToCartButtonTextColor: StyleParamType.Color;
  AddToCartButtonCornerRadius: StyleParamType.Number;
  AddToCartButtonBorderColor: StyleParamType.Color;
  AddToCartButtonBorderWidth: StyleParamType.Number;
  AddToCartButtonFillColorOpacity: StyleParamType.Color;
};

export default createStylesParams<IStylesParams>({
  productTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  productTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title'),
  },
  productPriceColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  productPriceFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-S'),
  },
  productDescriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  productDescriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-L'),
  },
  backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  imageBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2'),
  },
  inputFieldBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  inputFieldBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  inputFieldBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  inputFieldFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  },
  inputFieldColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  inputFieldTitlesColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  inputFieldTitlesFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  },
  ProductVariantsFillColorOpacity: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  ProductVariantsBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  ProductVariantsBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  ProductVariantsSelectedBoxColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  ProductVariantsTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  ProductVariantsTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  },
  BuyNowButtonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  },
  BuyNowButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  BuyNowButtonCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  BuyNowButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  BuyNowButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  BuyNowButtonFillColorOpacity: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },

  AddToCartButtonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  },
  AddToCartButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  AddToCartButtonCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  AddToCartButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  AddToCartButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  AddToCartButtonFillColorOpacity: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
});
