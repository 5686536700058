import React from 'react';
import { Composites, TextLabel, Thumbnails } from '@wix/wix-base-ui';
import { AlignLeft } from '../thumbnails/alignments/AlignLeft';
import { AlignCenter } from '../thumbnails/alignments/AlignCenter';
import { AlignRight } from '../thumbnails/alignments/AlignRight';

export enum Alignments {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

const Alignment = ({
  label,
  value,
  onChange,
}: {
  label: string;
  value: string;
  onChange: Function;
}) => {
  const alignmentThumbnails = [
    {
      value: Alignments.LEFT,
      illustration: <AlignLeft />,
    },
    {
      value: Alignments.CENTER,
      illustration: <AlignCenter />,
    },
    {
      value: Alignments.RIGHT,
      illustration: <AlignRight />,
    },
  ];

  return (
    <Composites.Thumbnails>
      <TextLabel value={label} />
      <Thumbnails
        value={value}
        onChange={onChange}
        maxThumbsPerRow={5}
        options={alignmentThumbnails}
      />
    </Composites.Thumbnails>
  );
};

export default Alignment;
