import React from 'react';

export const SuccessIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="16"
    viewBox="0 0 24 18"
  >
    <path d="M10.4689553,17.0302036 L10.4389553,17.0302036 C10.1399553,17.0202036 9.85995534,16.8782036 9.67695534,16.6402036 L6.20795534,12.1402036 C5.87095534,11.7032036 5.95195534,11.0752036 6.38995534,10.7382036 C6.82695534,10.3992036 7.45395534,10.4832036 7.79195534,10.9202036 L10.5169553,14.4542036 L16.7149553,7.34220361 C17.0799553,6.92620361 17.7119553,6.88420361 18.1259553,7.24520361 C18.5419553,7.60820361 18.5859553,8.23920361 18.2229553,8.65620361 L11.2229553,16.6872036 C11.0319553,16.9052036 10.7569553,17.0302036 10.4689553,17.0302036" />
  </svg>
);
