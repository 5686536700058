import { ActionMap } from '../../../../types/State';

type ActionPayload = {
  setPurchaseOptions: Partial<PurchaseOptions>;
};

export interface PurchaseOptions {
  variantId: string;
  recipientEmail: string;
  recipientName: string;
  greetingMessage: string;
  quantity: number;
  isGift: boolean;
  customAmount: number;
  deliverAt?: Date;
}

export interface CheckoutState {
  purchaseOptions: PurchaseOptions;
}

export const checkoutInitialState: CheckoutState = {
  purchaseOptions: {
    variantId: '',
    recipientEmail: '',
    recipientName: '',
    greetingMessage: '',
    quantity: 1,
    isGift: true,
    customAmount: 0,
  },
};

export type CheckoutActions =
  ActionMap<ActionPayload>[keyof ActionMap<ActionPayload>];

export const checkoutReducer = (
  state: CheckoutState,
  action: CheckoutActions,
) => {
  switch (action.type) {
    case 'setPurchaseOptions':
      const purchaseOptions = { ...state.purchaseOptions, ...action.payload };
      return {
        ...state,
        purchaseOptions,
      };
    default:
      return state;
  }
};
