import React from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import { IControllerProps } from '../../../types/app.types';

type WidgetAndControllerProps = WidgetProps<IControllerProps>;

type IUseControllerProps = () => IControllerProps;

type IControllerContext = (args: IStoreContext) => JSX.Element;

type IWithControllerContext = (
  Component: React.ComponentType,
) => (controllerProps: WidgetAndControllerProps) => JSX.Element;

type IStoreContext = {
  value: WidgetAndControllerProps;
  children: React.ReactNode;
};

const Context = React.createContext({} as IControllerProps);

export const useControllerProps: IUseControllerProps = () =>
  React.useContext(Context);

export const withControllerContext: IWithControllerContext =
  (Component) => (controllerProps) =>
    (
      <ControllerContext value={controllerProps as any}>
        <Component />
      </ControllerContext>
    );

const ControllerContext: IControllerContext = ({ value, children }) => (
  <Context.Provider value={value}>{children}</Context.Provider>
);
