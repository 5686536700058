import React from 'react';

import { useSettings } from '@wix/tpa-settings/react';
import s from './ProductVariants.scss';
import { classes } from './ProductVariants.st.css';
import { useControllerProps } from '../../ControllerContext';
import settingsParams from '../../../settingsParams';
import { BoxSelection, BoxSelectionBoxSize } from 'wix-ui-tpa';
import { useState } from '../../stores';

const ProductVariants = () => {
  const {
    state: { checkout },
    dispatch,
  } = useState();
  const settings = useSettings();
  const {
    presetVariants,
    currencyCode,
    locale,
    customVariantDisplay,
    customVariant,
  } = useControllerProps().giftCardStore;
  const { creatingCheckout, addingToCart } =
    useControllerProps().giftCardCheckoutStore;

  const variantDisplay = (value: number) => {
    if (customVariantDisplay) {
      return customVariantDisplay.replace('{value}', value.toString());
    }
    return formatCurrency(value);
  };

  const formatCurrency = (value: number) => {
    try {
      const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });

      return formatter.format(value);
    } catch (error) {
      return value.toString();
    }
  };

  const variantsOptions = () => {
    return presetVariants.map((variant) => (
      <BoxSelection.Option
        className={s.BoxSelectionOption}
        disabled={
          checkout.purchaseOptions.variantId !== variant.id &&
          (creatingCheckout || addingToCart)
        }
        id={variant.id}
        checked={checkout.purchaseOptions.variantId === variant.id}
      >
        {variantDisplay(Number(variant.value))}
      </BoxSelection.Option>
    ));
  };

  const customVariantOption = () => (
    <BoxSelection.Option
      id="custom"
      disabled={
        checkout.purchaseOptions.variantId !== 'custom' &&
        (creatingCheckout || addingToCart)
      }
      className={s.BoxSelectionOption}
      checked={checkout.purchaseOptions.variantId === 'custom'}
    >
      {settings.get(settingsParams.otherAmountVariantLabel)}
    </BoxSelection.Option>
  );

  return (
    <div className={s.variants}>
      <div className={s.label} style={{ position: 'relative' }}>
        <label>{settings.get(settingsParams.amountLabel)}</label>
      </div>
      <BoxSelection
        data-hook="rise-gift-card-product-variants-box-selection"
        inline
        className={classes.boxSelection}
        name="variants-selection"
        size={BoxSelectionBoxSize.small}
        onChange={({ id }) =>
          dispatch({
            type: 'setPurchaseOptions',
            payload: {
              variantId: id,
            },
          })
        }
      >
        {variantsOptions()}
        {customVariant ? customVariantOption() : ''}
      </BoxSelection>
    </div>
  );
};

export default ProductVariants;
