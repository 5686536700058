import React from 'react';
import cx from 'classnames';
import s from './ProductPageApp.scss';
import { StateProvider } from '../stores';

import ProductPageBody from '../Components/ProductPageBody/ProductPageBody';

function ProductPageApp() {
  const classNames = cx(s.main, s.viewer_classic);

  return (
    <div className={s.container}>
      <main className={classNames}>
        <StateProvider>
          <ProductPageBody />
        </StateProvider>
      </main>
    </div>
  );
}

export default ProductPageApp;
