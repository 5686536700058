import React from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import s from './Headline.scss';
import settingsParams from '../../../settingsParams';
import { useControllerProps } from '../../ControllerContext';
import { Property } from 'csstype/index';

const Headline = () => {
  const settings = useSettings();
  const { product } = useControllerProps().giftCardStore;

  return (
    <div className={s.headline}>
      <h1
        data-hook="rise-gift-card-headline"
        className={s.title}
        style={{
          textAlign: settings.get(
            settingsParams.productTitleAlignment,
          ) as Property.TextAlign,
        }}
      >
        {product?.name}
      </h1>
    </div>
  );
};

export default Headline;
